<template>
  <div class="withdrawal-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="card-box" v-if="userInfo">
      <div class="box">
        <div class="box-title">
          <span>账户余额（元）</span>
          <img src="@/assets/images/income/exclamation-mark.png" alt="图片" />
        </div>
        <div class="advance">
          <div class="number">{{ userInfo.balance }}</div>
          <el-button @click="cashVisible = true">提现</el-button>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <span>冻结金额</span>
          <img src="@/assets/images/income/interrogation-mark.png" alt="图片" />
        </div>
        <div class="number-box">
          <div class="number">{{ userInfo.frozenCash }}</div>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <span>累计收入</span>
          <img src="@/assets/images/income/interrogation-mark.png" alt="图片" />
          <span class="detailed" @click="changeDetailed(0)">明细</span>
        </div>
        <div class="number-box">
          <div class="number">{{ userInfo.income }}</div>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <span>累计支出</span>
          <img src="@/assets/images/income/interrogation-mark.png" alt="图片" />
          <span class="detailed" @click="changeDetailed(1)">明细</span>
        </div>
        <div class="number-box">
          <div class="number">{{ userInfo.expenses }}</div>
        </div>
      </div>
    </div>

    <div class="table-box">
      <!-- 筛选 -->
      <div class="screen">
        <div class="screen-left">
          <el-date-picker v-model="orderDate" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="changeOrderDate" popper-class="picker-date-style" value-format="yyyy-MM-dd">
          </el-date-picker>
          <div class="screen-box">
            <span class="title">任务状态：</span>
            <el-select v-model="orderStatus" @change="getOrders">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="screen-right">
          <el-input v-model="orderKey" placeholder="请输入订单号" maxlength="1000"></el-input>
          <el-button type="primary" @click="getOrders">搜索<i class="el-icon-search el-icon--right"></i></el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table height="540" :data="withdrawalList" header-row-class-name="header-row">
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="number" label="订单号" width="180">
          </el-table-column>
          <el-table-column prop="cash" label="提现金额（元）">
          </el-table-column>
          <el-table-column prop="dateline" label="提现时间" width="180">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="120">
            <template slot-scope="scope">
              <div class="row-state">
                <span v-if="scope.row.status === 1" style="background: #698af7"></span>
                <span v-if="scope.row.status === 2" style="background: #81e0af"></span>
                <span v-if="scope.row.status === 3" style="background: #fa7962"></span>

                <div v-if="scope.row.status === 1">审核中</div>
                <div v-if="scope.row.status === 2">已通过</div>
                <div v-if="scope.row.status === 3">未通过</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="remarks" label="备注">
            <template slot-scope="scope">
              {{ !scope.row.remarks ? "无" : scope.row.remarks }}
            </template>
          </el-table-column>
          <el-table-column label="发票信息" width="100">
            <template slot-scope="scope">
              <span class="check" @click="getOrdersDetails(scope.row.id)">查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination-box">
        <span class="total-pages">共{{ orderPageCount }}页</span>
        <el-pagination @size-change="getOrders" @current-change="getOrders" :current-page.sync="orderPage"
          :page-size="orderSize" background layout="prev, pager, next,jumper" :total="orderTotal">
        </el-pagination>
      </div>
      <!-- 页脚 -->
      <div class="footer-fa">
        <Footer></Footer>
      </div>
      <!-- 三个弹出框 -->
      <!-- 提现 -->
      <el-dialog :visible.sync="cashVisible" width="524px" :before-close="cashClose" class="cash">
        <img class="close" @click="cashClose" src="@/assets/images/income/close.png" alt="图片" />
        <div class="box">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>提现上限：</span>
          </div>
          <el-input slot="reference" placeholder="最大提现金额" maxlength="1000" v-model="cashForm.maxAmount"
            :disabled="true"></el-input>
        </div>
        <div class="box">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>提现金额：</span>
          </div>
          <el-popover placement="bottom" width="200" trigger="manual" content="余额不足，超过了最大提现金额" v-model="cashNumberVisible"
            popper-class="manual">
            <el-input slot="reference" placeholder="请输入提现金额" maxlength="1000" v-model="cashForm.amount"
              @input="inputChange(1)"></el-input>
          </el-popover>
        </div>
        <div class="box">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>发票编号：</span>
          </div>
          <el-input slot="reference" placeholder="请输入发票编号" v-model="cashForm.fapiaoNumber" maxlength="1000"></el-input>
        </div>
        <div class="box">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>发票时间：</span>
          </div>

          <el-date-picker v-model="cashForm.fapiaoDate" type="date" placeholder="选择日期" popper-class="picker-date-style"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="box">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>发票金额：</span>
          </div>
          <el-input slot="reference" placeholder="请输入发票金额" v-model="cashForm.fapiaoAmountOfMoney" maxlength="1000"
            @input="inputChange(2)"></el-input>
        </div>
        <div class="box upload">
          <div class="box-title">
            <img class="close" src="@/assets/images/income/star.png" alt="图片" />
            <span>发票扫描件：</span>
          </div>
          <el-upload action="#" class="card-upload" :http-request="beforeUpload" ref="uploadRef">
            <el-button size="small" type="primary">点击上传</el-button>
            <span class="prompt">如果上传多个图片,请将其合并成pdf文件上传</span>
          </el-upload>
        </div>
        <div class="file-img" v-if="fileImg" @click="windowOpen">
          <i class="el-icon-document"></i>
          <span class="name">{{ fileName }}</span>
        </div>
        <div class="submit-box">
          <el-button class="submit" @click="setCreateOrder" :loading="progressFlag">{{ progressFlag ? "上传中.." : "提交"
          }}</el-button>
        </div>
      </el-dialog>
      <!-- 明细 -->
      <el-dialog :visible.sync="detailedVisible" width="800px" :before-close="detailedClose" class="detailed">
        <img class="close" @click="detailedClose" src="@/assets/images/income/close.png" alt="图片" />
        <div class="title">明细</div>
        <div class="screen">
          <el-select v-model="detailedActionId" @change="getCashLogs">
            <el-option label="支出" :value="1"> </el-option>
            <el-option label="收入" :value="0"> </el-option>
          </el-select>
          <div class="detailed-categoryId">
            <div class="categoryId-title">变动分类:</div>
            <el-select v-model="detailedCategoryId" @change="getCashLogs">
              <el-option label="收入" :value="1"> </el-option>
              <el-option label="提现" :value="2"> </el-option>
              <el-option label="QC" :value="3"> </el-option>
              <el-option label="补发奖励" :value="4"> </el-option>
            </el-select>
          </div>
          <el-date-picker v-model="detailedDate" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="changeDetailedDate" popper-class="picker-date-style"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="table-box2">
          <!-- 表格 -->
          <div class="table2">
            <el-table height="160" :data="detailedList" header-row-class-name="header-row">
              <el-table-column prop="dateline" label="时间"> </el-table-column>
              <el-table-column prop="description" label="变动说明" min-width="200px">
              </el-table-column>
              <el-table-column prop="cash" label="金额"> </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="pagination-box">
            <span class="total-pages">共{{ detailedPageCount }}页</span>
            <el-pagination @size-change="getCashLogs" @current-change="getCashLogs" :current-page.sync="detailedPage"
              :page-size="detailedSize" background layout="prev, pager, next,jumper" :total="detailedTotal">
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <!-- 查看 -->
      <el-dialog :visible.sync="checkVisible" width="600px" :before-close="checkClose" class="detailed">
        <img class="close" @click="checkClose" src="@/assets/images/income/close.png" alt="图片" />
        <div class="title">发票信息</div>
        <div class="box">
          <div class="box-title">发票编号：</div>
          <div class="box-value">{{ checkForm.fapiaoNumber }}</div>
        </div>
        <div class="box">
          <div class="box-title">发票时间：</div>
          <div class="box-value">{{ checkForm.fapiaoDate }}</div>
        </div>
        <div class="box">
          <div class="box-title">发票金额：</div>
          <div class="box-value">{{ checkForm.fapiaoAmountOfMoney }}</div>
        </div>
        <div class="box box-img">
          <div class="box-title">发票扫描件：</div>
          <img :src="checkForm.fapiaoUri" alt="发票" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import {
  getOrders,
  getCashLogs,
  setCreateOrder,
  getOrdersDetails,
  setFapiaoUpload,
} from "@/api/app";
export default {
  components: { Breadcrumb, Footer },
  data() {
    return {
      list: [
        {
          name: "我的收益",
          path: null,
        },
        {
          name: "我的提现",
          path: null,
        },
      ],
      statusOptions: [
        {
          value: null,
          label: "全部",
        },
        { value: 1, label: "审核中" },
        { value: 2, label: "已通过" },
        { value: 3, label: "未通过" },
      ],
      withdrawalList: null,
      detailedList: null,
      cashVisible: false,
      detailedVisible: false,
      checkVisible: false,
      cashForm: {
        amount: null,
        fapiaoNumber: null,
        fapiaoDate: "",
        fapiaoAmountOfMoney: null,
        fapiaoUri: null,
        maxAmount: null
      },
      checkForm: {
        fapiaoNumber: null,
        fapiaoDate: "",
        fapiaoAmountOfMoney: null,
        fapiaoUri: null,
      },
      cashNumberVisible: false,
      disabled: false,
      fileImg: null,
      fileName: null,
      detaileSelect: null,
      detaileDateValue: null,
      detaileSearch: null,
      orderPage: 1, //订单页数
      orderSize: 10,
      orderTotal: null,
      orderPageCount: null,
      orderStatus: null,
      orderKey: null,
      orderStartDate: null,
      orderEndDate: null,
      orderDate: null,
      detailedPage: 1, //明细页数
      detailedSize: 10,
      detailedTotal: null, //总条数
      detailedPageCount: null, //总页数
      detailedDate: null, //筛选日期
      detailedCategoryId: null,
      detailedActionId: null,
      detailedStartDate: null,
      detailedEndDate: null,
      progressFlag: false,
    };
  },
  watch: {
    cashVisible: {
      handler(newValue, oldValue){
        if (newValue) {
          if (this.userInfo.maxRatio > 0) {
            this.cashForm.maxAmount = (this.userInfo.balance * this.userInfo.maxRatio).toFixed(2)
          } else {
            this.cashForm.maxAmount = this.userInfo.balance
          }
        } else {
          this.cashForm.maxAmount = 0
        }
      }
    }
  },
  created() {
    this.getOrders();
  },
  mounted() {
    if (this.$route.query.isShow) {
      this.cashVisible = true;
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    handleCurrentChange() { },
    handleSizeChange() { },
    //预览上传图片或pdf
    windowOpen() {
      window.open(this.fileImg);
    },
    //提现关闭触发
    cashClose() {
      this.cashVisible = false;
      this.cashNumberVisible = false;
      this.cashForm.amount = null;
    },
    //明细关闭触发
    detailedClose() {
      this.detailedVisible = false;
    },
    // 查看关闭触发
    checkClose() {
      this.checkVisible = false;
    },
    //设置输入框只能输入数字
    inputChange(type) {
      if (type === 1) {
        this.cashForm.amount = this.cashForm.amount.replace(
          /^(0+)|[^\d]+/g,
          ""
        );
        if (this.cashForm.amount > this.userInfo.balance * this.userInfo.maxRatio) {
          this.cashNumberVisible = true;
        } else {
          this.cashNumberVisible = false;
        }
      } else {
        this.cashForm.fapiaoAmountOfMoney =
          this.cashForm.fapiaoAmountOfMoney.replace(/^(0+)|[^\d]+/g, "");
      }
    },
    beforeUpload(file) {
      console.log("file,触发", file.file);
      var img = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);
      this.fileName = file.file.name;
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      console.log("file,触发", img);
      const isLt1M = file.file.size / 1024 / 1024 < 10;
      if (!suffix && !suffix2 && !suffix3 && !suffix4) {
        this.$message.error("只能上传图片和pdf文件！");
        const index = this.$refs.uploadRef.uploadFiles.findIndex(
          (e) => e.uid === file.file.uid
        );
        this.$refs.uploadRef.uploadFiles.splice(index, 1);
        this.fileImg = "";
        return false;
      }
      // // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        const index = this.$refs.uploadRef.uploadFiles.findIndex(
          (e) => e.uid === file.file.uid
        );
        this.$refs.uploadRef.uploadFiles.splice(index, 1);
        this.fileImg = "";
      }
      // this.fileImg = file.File.url;
      this.fileImg = 123;
      const formData = new FormData();
      formData.append("Files", file.file);

      this.progressFlag = true;
      setFapiaoUpload(formData).then((res) => {
        this.progressFlag = false;
        if (res.data.url) {
          this.fileImg = `${res.data.url}?from=${res.data.from}&ts=${res.data.timestamp}&sn=${res.data.sign}`;
        } else {
          this.fileImg = res.data
        }
      });
    },
    handleRemove(file) {
      const index = this.$refs.uploadRef.uploadFiles.findIndex(
        (e) => e.uid === file.uid
      );
      this.$refs.uploadRef.uploadFiles.splice(index, 1);
      setTimeout(() => {
        this.fileImg = "";
      }, 1000);
    },
    //提现列表
    getOrders() {
      const params = {
        page: this.orderPage,
        size: this.orderSize,
        status: this.orderStatus,
        key: this.orderKey,
        startDate: this.orderStartDate,
        endDate: this.orderEndDate,
      };
      getOrders(params).then((res) => {
        console.log(123456, res.data);
        this.withdrawalList = res.data.data;
        this.orderTotal = res.data.dataCount;
        this.orderPageCount = res.data.pageCount;
      });
    },
    //提现筛选日期范围
    changeOrderDate() {
      if (this.orderDate) {
        this.orderStartDate = this.orderDate[0];
        this.orderEndDate = this.orderDate[1];
      } else {
        this.orderStartDate = "";
        this.orderEndDate = "";
      }
      this.getOrders();
    },
    //明细
    changeDetailed(type) {
      this.detailedActionId = parseInt(type);
      this.detailedVisible = true;
      this.getCashLogs();
    },
    //明细筛选日期范围
    changeDetailedDate() {
      if (this.detailedDate) {
        this.detailedStartDate = this.detailedDate[0];
        this.detailedEndDate = this.detailedDate[1];
      } else {
        this.detailedStartDate = "";
        this.detailedEndDate = "";
      }
      this.getCashLogs();
    },
    //获取明细
    getCashLogs() {
      const params = {
        page: this.detailedPage,
        size: this.detailedSize,
        actionId: this.detailedActionId,
        startDate: this.detailedStartDate,
        endDate: this.detailedEndDate,
        categoryId: this.detailedCategoryId,
      };
      getCashLogs(params).then((res) => {
        console.log(123, res.data);
        if (res.status === 200) {
          this.detailedList = res.data.data;
          this.detailedTotal = res.data.dataCount;
          this.detailedPageCount = res.data.pageCount;
        }
      });
    },
    //提现
    setCreateOrder() {
      if (!this.cashForm.amount) {
        this.$message({
          showClose: true,
          message: "提现金额不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.cashForm.fapiaoNumber) {
        this.$message({
          showClose: true,
          message: "发票编号不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.cashForm.fapiaoDate) {
        this.$message({
          showClose: true,
          message: "发票时间不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.cashForm.fapiaoAmountOfMoney) {
        this.$message({
          showClose: true,
          message: "发票金额不能为空",
          type: "warning",
        });
        return;
      }
      console.log("this.cashForm", this.cashForm);
      this.cashForm.fapiaoUri = this.fileImg;
      setCreateOrder(this.cashForm).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.getOrders();
        }
        this.cashClose();
      });
    },
    //发票信息
    getOrdersDetails(id) {
      getOrdersDetails(id).then((res) => {
        console.log(122333333, res.data);
        if (res.status === 200) {
          this.checkVisible = true;
          this.checkForm.fapiaoNumber = res.data.fapiao_number;
          this.checkForm.fapiaoDate = res.data.fapiao_date;
          this.checkForm.fapiaoAmountOfMoney = res.data.fapiao_amount_of_money;
          this.checkForm.fapiaoUri = res.data.fapiao_uri;
          console.log("1112343534545", this.checkForm);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.withdrawal-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);

  .card-box {
    width: 100%;
    height: 114px;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .box {
      width: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .box-title {
        display: flex;
        align-items: center;
        margin-top: 26px;

        span {
          font-size: 14px;
          color: #9091a1;
        }

        img {
          width: 15px;
          height: 15px;
          margin: 0 5px;
        }

        .detailed {
          color: #4578ff;
          cursor: pointer;
        }
      }

      .advance {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .number {
          font-weight: bold;
          font-size: 32px;
          color: #06052b;
          margin-right: 5px;
        }

        .el-button {
          width: 75px;
          height: 32px;
          padding: 0;
          background: #4578ff;
          border-radius: 4px;
          color: #fff;
        }
      }

      .number-box {
        display: flex;
        width: 100%;
        justify-content: center;

        .number {
          font-weight: bold;
          font-size: 32px;
          color: #06052b;
          margin-top: 15px;
        }
      }
    }

    .upload {
      display: flex;
    }
  }

  .table-box {
    width: 100%;
    min-height: calc(100vh - 313px);
    margin-top: 20px;
    background-color: #fff;
    padding-top: 26px;

    .screen {
      display: flex;
      justify-content: space-between;

      /deep/ .el-input__inner {
        height: 36px;
      }

      .screen-left {
        display: flex;
        margin-left: 26px;

        .screen-box {
          display: flex;
          align-items: center;
          margin-left: 50px;

          .title {
            color: #333333;
            font-size: 14px;
            margin-right: 10px;
          }

          .el-select {
            width: 154px;
          }
        }
      }

      .screen-right {
        display: flex;
        margin-right: 24px;

        .el-input {
          width: 180px;
          margin-right: 7px;
        }

        .el-button {
          width: 84px;
          height: 36px;
          padding: 0cm;
          background: #4578ff;
          border-radius: 4px;
        }
      }
    }

    .table {
      width: calc(100% - 50px);
      margin-left: 26px;
      margin-top: 20px;

      .el-table {
        width: 100%;
        min-height: 540px;
        margin-top: 27px;

        /deep/ .header-row {
          background-color: #f5f7fd;

          .el-table__cell {
            background-color: #f5f7fd;
            color: #06052b;
            font-size: 14px;
            font-weight: normal;
            text-align: center;
          }
        }

        /deep/ .el-table__cell {
          text-align: center;
        }

        .no-wrap {
          white-space: nowrap;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .row-state {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            width: 7px;
            height: 7px;
            background: #698af7;
            border-radius: 7px;
            margin-right: 8px;
            margin-left: 10px;
          }
        }

        .check {
          color: #4578ff;
          cursor: pointer;
        }

        .operate {
          display: flex;
          justify-content: center;
          font-size: 14px;
          color: #4578ff;

          .left {
            margin-right: 20px;
          }

          div {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  .total-pages {
    color: #323233;
    font-size: 14px;
    margin-right: 18px;
  }

  .el-pagination {
    margin-right: 20px;
  }
}

.footer-fa {
  padding-right: 20px;
  background-color: #fff;

  /deep/ .home-page-footer {
    margin-bottom: 0 !important;
    padding-bottom: 25px;
  }
}

.cash {
  .close {
    float: right;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .box {
    display: flex;
    align-items: center;
    clear: both;
    margin-bottom: 24px;

    .box-title {
      display: flex;
      width: 100px;
      justify-content: flex-end;
      margin-right: 20px;
    }

    .el-input {
      width: 320px;

      /deep/ .el-input__inner {
        height: 36px;
      }

      /deep/ .el-input__prefix {
        top: -2px;
      }
    }
  }

  .upload {
    align-items: flex-start;
  }

  .submit-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .submit {
      width: 100px;
      padding: 0;
      height: 36px;
      background: #4578ff;
      border-radius: 4px;
      color: #fff;
      margin-right: 40px;
    }
  }
}

.card-upload {
  display: flex;
  justify-content: center;

  /deep/ .el-upload-list {
    display: none;
  }

  /deep/ .el-upload--picture-card {
    width: 322px;
    height: 150px;
    border: 1px solid #c0c4cc;
  }

  /deep/ .el-upload-list__item {
    width: 322px;
    height: 150px;
  }

  /deep/ .el-upload--text {
    display: flex;
    flex-direction: column;

    .el-button {
      width: 100px;
    }

    .prompt {
      font-size: 12px;
      color: #999;
      margin-top: 10px;
    }
  }

  /deep/ .default {
    line-height: 20px;
    font-size: 12px;
    margin-top: 26px;

    .img-box {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 56px;
      height: 56px;
      border: 1px dashed #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
      }
    }

    .prompt {
      color: #dddddd;
      margin-top: 24px;
    }
  }
}

.file-img {
  display: flex;
  align-items: center;
  margin-left: 120px;
  position: relative;
  top: -10px;
  cursor: pointer;

  i {
    margin-right: 10px;
  }
}

.is-file-list {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}

.detailed {
  .close {
    float: right;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #06052b;
    font-weight: bold;
  }

  .screen {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;

    .el-select {
      width: 90px;
    }

    .box-search {
      display: flex;

      .el-input {
        width: 132px;
        margin-right: 10px;
      }

      .el-button {
        width: 84px;
        height: 36px;
        background: #4578ff;
        padding: 0;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}

.table-box2 {
  .table2 {
    width: 100%;
    margin-top: 20px;

    .el-table {
      width: 100%;
      min-height: 360px;
      margin-top: 27px;

      /deep/ .header-row {
        background-color: #f5f7fd;

        .el-table__cell {
          background-color: #f5f7fd;
          color: #06052b;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
        }
      }

      /deep/ .el-table__cell {
        text-align: center;
      }

      .no-wrap {
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .row-state {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 7px;
          height: 7px;
          background: #698af7;
          border-radius: 7px;
          margin-right: 8px;
          margin-left: 10px;
        }
      }

      .check {
        color: #4578ff;
        cursor: pointer;
      }

      .operate {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #4578ff;

        .left {
          margin-right: 20px;
        }

        div {
          cursor: pointer;
        }
      }
    }
  }
}

.detailed {
  .close {
    float: right;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #06052b;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .box-title {
      width: 100px;
      text-align: right;
      margin-right: 20px;
    }

    .box-value {
      width: 326px;
      height: 36px;
      line-height: 36px;
      background: #fafafa;
      border-radius: 4px;
    }
  }

  .box-img {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;

    img {
      width: 326px;
      height: 151px;
    }
  }

  .detailed-categoryId {
    display: flex;
    align-items: center;

    .categoryId-title {
      margin-right: 10px;
    }

    .el-select {
      width: 150px;
    }
  }
}
</style>
<style lang="scss" scoped>
// 三个弹出框
.el-dialog__wrapper {
  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
</style>
<style>
.manual {
  padding: 0;
  background-color: #f2f2f2;
  color: #ccc;
  text-align: center;
  padding: 6px 0;
}

.manual[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #f2f2f2;
}
</style>